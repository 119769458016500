<template>
  <div class="box">
    <div class="text">
      <p>
        <a>作品拍摄制作及送报：</a>
        <span>（1）作品内容：按所报名专业组对应的竞赛内容拍摄； </span>
        <span
          >（2）视频要求：按16:9手机或相机横屏拍摄，清晰无抖动，保证评委能准确识别参赛者的操作，如关键步骤因拍摄原因不能清晰呈现，评审有权扣分；可加配音、旁白和字幕；</span
        >
        <span
          >（3）操作场景：建议在真实的治疗场景操作并拍摄，请注意取得患者知情同意并注意保护患者隐私；</span
        >
        <span>（4）脱敏处理：必须隐去视频中参赛者姓名、医院名称等身份信息；</span>
        <span>（5）单位审核：作品上传前须经所在单位审核、把关；</span>
        <span style="display:flex">（6）<span>拍摄作品使用设备品牌不限</span></span>
      </p>
      <p>
        <a>血透组：经自体动静脉内瘘穿刺行血液透析治疗上机的护理操作</a>
        <span>视频连贯一镜到底不允许快进、暂停或剪辑等处理，时长20分钟以内</span>
      </p>
      <p>
        <a>技师组：透析机超滤功能检测与校准</a>
        <span
          >拍摄内容包括两部分：①透析机超滤功能的检测（模拟透析脱水），需重点展示模拟透析脱水操作过程，模拟脱水观察时间至少15分钟可快进剪辑，口述脱水误差结果；②超滤泵校准，展示超滤泵校准全过程；时长20分钟以内；</span
        >
      </p>
      <p>
        <a>腹透组：腹膜透析换液操作</a>
        <span
          >拍摄内容为一次完整的腹膜透析手工换液操作（CAPD），包括：物品准备、从连接至断开、注意事项；视频连贯一镜到底不允许快进、暂停或剪辑等处理，时长20—30分钟；</span
        >
      </p>
      <p>
        <a>CRRT组：连续性肾脏替代治疗(CRRT)管路安装与预冲</a>
        <span>操作场景：建议在清洁安静的空间；治疗模式：CVVHDF；拍摄起止点：从环境评估和物品准备开始，到预冲完毕待上机结束，不含机器自检；视频连贯一镜到底不允许快进、暂停或剪辑等处理，时长30分钟以内。</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.box {
  width: 100%;
  @media screen and (max-width: 520px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .red{
    color: red !important;
  }
  background: #ffffff;
  padding: 50px 0;
  .text {
    margin: 40px auto 0;
    padding: 0 180px;
    @media screen and (max-width: 520px) {
      padding: 0 25px;
    }
    p {
      font-size: 20px;
      color: #39373b;
      line-height: 35px;
      padding-bottom: 20px;
      @media screen and (max-width: 520px) {
        font-size: 14px;
        line-height: 20px;
      }
      a {
        color: #05258e;
        font-weight: 600;
        display: block;
        @media screen and (max-width: 520px) {
          padding-bottom: 10px;
        }
      }
      span {
        display: block;
      }
    }
  }
}
</style>
