<template>
  <div class="footercard">
    <div class="ewm">
      <!-- <div class="box">
        <img
          src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ewm-qun.jpg"
          alt=""
        />
        <p>扫码进群</p>
        <h3>了解更多信息</h3>
      </div> -->
      <div class="box">
        <img src="../assets/image/index-img/ewm.webp" alt="" />
        <p>关注微信公众号</p>
        <h3>查看最新大赛信息</h3>
      </div>
      <div class="box">
        <img
          src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ewm-kefu.jpg"
          alt=""
        />
        <p>若群二维码过期</p>
        <h3>请添加客服备注进群</h3>
      </div>
    </div>
    <div class="zbf">
      <p>主办单位：国家卫生健康委能力建设和继续教育中心</p>
      <p>承办单位：国医延长（北京）医学技术服务有限公司</p>
      <p>协办单位：北京大学人民医院</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["workstate"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.footercard {
  @media screen and (max-width: 520px) {
    // background-image: url("../assets/image/index-img/bg.webp"),
    //   url("../assets/image/index-img/bg2.webp");
    background-position: top, left;
    background-repeat: no-repeat, no-repeat;
    margin-top: -10px;
  }
  .ewm {
    padding-top: 10px;
    margin: 0 auto 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 520px) {
      margin: 0 auto 20px;
    }
    .box {
      width: 238px;
      padding: 10px 0;
      background: #eef1f6;
      margin: 0 20px;
      @media screen and (max-width: 520px) {
        flex: 1;
        margin: 0 5px;
      }
      img {
        display: block;
        width: 140px;
        height: 140px;
        margin: 50px auto 12px;
        @media screen and (max-width: 520px) {
          width: 100px;
          height: 100px;
          margin: 30px auto 12px;
        }
      }
      p {
        color: #363636;
        padding-bottom: 12px;
        @media screen and (max-width: 520px) {
          font-size: 14px;
        }
      }
      h3 {
        font-size: 18px;
        color: #363636;
        padding: 0 15px;
         @media screen and (max-width: 520px) {
          font-size: 15px;
        }
      }
    }
  }
  .zbf {
    p {
      text-align: center;
      font-size: 18px;
      color: #05258e;
      padding: 0 10px;
      padding-top: 12px;
      @media screen and (max-width: 520px) {
        font-size: 13px;
      }
    }
    padding-bottom: 140px;
    @media screen and (max-width: 520px) {
      padding-bottom: 80px;
    }
  }
}
</style>
