<template>
  <div class="signup" @click="offHospital()" v-loading.fullscreen.lock="videoIsUPloding">
    <div class="content">
      <div class="title">报名参赛</div>
      <div class="form">
        <el-form
          label-position="left"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="作者姓名:" prop="username">
            <el-input
              v-model="ruleForm.username"
              class="inputWidth"
              placeholder="请输入您的姓名"
              :disabled="inputState"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话号码:" prop="mobile">
            <el-input
              v-model="ruleForm.mobile"
              class="inputWidth"
              placeholder="请输入您的电话号码"
              :disabled="inputState"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱:" prop="email">
            <el-input
              v-model="ruleForm.email"
              class="inputWidth"
              placeholder="请输入您的电子邮箱"
              :disabled="inputState"
            ></el-input>
          </el-form-item>
          <el-form-item label="参赛组别:" prop="cszb">
            <div class="groupBtns">
              <button
                v-for="item in zubieList"
                :key="item.id"
                @click.prevent="mygroupChange(item.id)"
                :class="ruleForm.cszb == item.id ? 'glbtn' : ''"
                :disabled="inputState"
              >
                {{ item.name }}
              </button>
            </div>
          </el-form-item>
          <el-form-item label="职称选择:" prop="zhicheng_id">
            <el-radio-group v-model="ruleForm.zhicheng_id">
              <el-radio
                :disabled="inputState"
                :label="item.id"
                v-for="item in zhichengList"
                :key="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="地区选择:" prop="city">
            <div class="adress">
              <el-select
                :disabled="inputState"
                v-model="ruleForm.province"
                placeholder="请选择省份"
                @change="provinceChange()"
              >
                <el-option
                  v-for="item in mapList.province"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                :disabled="inputState"
                v-model="ruleForm.city"
                placeholder="请选择城市"
                @change="cityChange()"
              >
                <el-option
                  v-for="item in mapList.city"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                :disabled="inputState"
                v-model="ruleForm.district"
                placeholder="请选择区域"
              >
                <el-option
                  v-for="item in mapList.area"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="医院全称:" prop="hospital">
            <!-- <el-input
              v-model="ruleForm.hospital"
              class="inputWidth"
              @input="hospitalChange2()"
              placeholder="请手动输入您的医院名称"
              v-if="switchValue"
            ></el-input> -->
            <el-input
              :disabled="inputState"
              v-model="ruleForm.hospital"
              class="inputWidth"
              @input="hospitalChange()"
              placeholder="请输入您的医院名称"
            ></el-input>

            <!-- <el-tooltip
              content="切换至手动录入医院"
              placement="top"
              class="myswitch"
            >
              <el-switch
                v-model="switchValue"
                active-color="rgb(91 45 155)"
                inactive-color="rgb(120 115 115)"
                @change="ruleForm.hospital = ''"
              >
              </el-switch>
            </el-tooltip> -->
            <div
              class="hospitalList"
              v-if="hospitalListIsShow"
              v-loadMore="hospitalDel"
            >
              <ul class="hospitalListMore">
                <li
                  v-for="item in mapList.hospital"
                  :key="item.id"
                  @click.stop="choiceHospital(item)"
                >
                  <p>{{ item.name }}</p>
                </li>
              </ul>
            </div>
            <!-- <el-select
                v-model="ruleForm.hospital"
                placeholder="请选择医院"
                v-loadMore="loadMore"
              >
                <el-option
                  v-for="item in mapList.hospital"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
          </el-form-item>
          <el-form-item label="科室选择:" prop="department_id">
            <el-radio-group v-model="ruleForm.department_id">
              <el-radio
                :disabled="inputState"
                :label="item.id"
                v-for="item in DepartmentList"
                :key="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="上传证明:"
            prop="gzzpath"
            style="padding-bottom: 20px"
          >
            <div class="uploader">
              <el-upload
                :disabled="inputState"
                class="avatar-uploader"
                accept=".jpg,.png"
                name="file"
                :action="picURL + '/Wechat/UploadRelevantCertificates'"
                :show-file-list="false"
                :auto-upload="true"
                :data="{ user_id: ruleForm.user_id, phase: phase }"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.gzzpath"
                  :src="picURL + ruleForm.gzzpath"
                  class="avatar"
                />
                <div
                  class="clear"
                  @click.stop="
                    () => {
                      ruleForm.gzzpath = '';
                    }
                  "
                  v-if="ruleForm.gzzpath"
                >
                  x
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span class="tit">工作证</span>
              </el-upload>
              <el-upload
                :disabled="inputState"
                class="avatar-uploader"
                accept=".jpg,.png"
                name="file"
                :action="picURL + '/Wechat/UploadRelevantCertificates'"
                :show-file-list="false"
                :auto-upload="true"
                :data="{ user_id: ruleForm.user_id, phase: phase }"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.dwzmpath"
                  :src="picURL + ruleForm.dwzmpath"
                  class="avatar"
                />
                <div
                  class="clear"
                  @click.stop="
                    () => {
                      ruleForm.dwzmpath = '';
                    }
                  "
                  v-if="ruleForm.dwzmpath"
                >
                  x
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span class="tit">单位证明&nbsp;&nbsp; </span>
                <div class="tit2" @click.stop="dialog2Show()">
                  (点击下载证明范本)
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <div class="height"></div>
          <el-form-item label="上传作品:" class="videoUP" prop="path">
            <p class="tishi">
              *建议参赛者自行上传的视频资料最好由所在单位审核、把关后再上传。<span
                class="btnText"
                @click="dialogVisible = true"
                >作品要求</span
              >
            </p>
            <div class="upvideo">
              <el-upload
                class="avatar-uploader"
                action
                drag
                :show-file-list="false"
                :on-success="videohandleAvatarSuccess"
                :before-upload="videobeforeAvatarUpload"
                :auto-upload="false"
                :on-change="httpRequest"
                :disabled="inputState"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__text">
                  将视频文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传MP4文件，且不超过800MB
                </div>
              </el-upload>
              <div class="myvideo">
                <video
                  v-if="ruleForm.path"
                  width="100%"
                  height="100%"
                  controls
                  ref="video"
                >
                  <source
                    :src="aliyunURL + '/' + ruleForm.path"
                    type="video/mp4"
                  />
                  <source src="movie.ogg" type="video/ogg" />
                  您的浏览器不支持Video标签。
                </video>
              </div>
              <!-- <div class="upload-type">
                <button @click="stsUpload" :disabled="uploadDisabled">
                  开始上传
                </button>
                <button @click="pauseUpload" :disabled="pauseDisabled">
                  暂停
                </button>
                <button :disabled="resumeDisabled" @click="resumeUpload">
                  恢复上传
                </button>
                <span class="progress"
                  >上传进度: <i id="sts-progress">{{ stsProgress }}</i> %</span
                >
              </div> -->
              <el-progress
                color="#05258E"
                :percentage="stsProgress"
              ></el-progress>
            </div>
          </el-form-item>
          <el-form-item label="视频简介:" prop="intro">
            <el-input
              :disabled="inputState"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.intro"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              class="submit"
              :disabled="workInputState"
              >提交审核</el-button
            >
          </el-form-item>
        </el-form>
        <!-- 对话框 -->
        <el-dialog
          title="作品要求"
          :visible.sync="dialogVisible"
          :width="clientWidth<540?'80%':'60%'"
          :before-close="handleClose"
          class="mydialog"
        >
          <workTemplate></workTemplate>
        </el-dialog>
        <el-dialog
          :visible.sync="dialog2"
          :width="clientWidth<540?'80%':'30%'"
          class="mydialog"
          title="单位证明模板"
        >
          <div class="text2">
            <img src="../../assets/image/dwzm.png" alt="" />
            <a
              href="http://www.gyycbj.com/uploads/jnds/透析专项技能大赛-单位证明.docx"
              >点击下载模板</a
            >
          </div>
        </el-dialog>
      </div>
    </div>
    <footerCard class="footer"></footerCard>
  </div>
</template>
<script>
import footerCard from "../../components/footerCard.vue";
import workTemplate from "../../components/workTemplate.vue";
import { getAreas, searchHospital } from "../API/city";
import {
  getBmInfo,
  setBaoming,
  getEntryInformation,
  getUserInfo,
  getSTSToken,
} from "../API/user";
import { getToken, hasToken, setToken } from "../../assets/js/storage";
// import {checkState} from '../API/login'
import axios from "axios";
export default {
  data() {
    return {
      clientWidth :document.body.clientWidth,
      switchValue: false,
      videoIsUPloding: false,
      dialog2: false,
      aliyunURL: "https://vod.gyycbj.com", //视频,
      picURL: "http://www.gyycbj.com",
      phase: localStorage.getItem("phase"),
      // 上传阿里云视频所需参数
      videoIsUp: false,
      timeout: "",
      partSize: "",
      parallel: "",
      retryCount: "",
      retryDuration: "",
      region: "cn-shanghai",
      userId: "1637948111372003",
      file: null,
      stsProgress: 0, //进度条
      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      statusText: "",
      pauseDisabled: true,
      uploader: null,
      // 报名表单
      ruleForm: {
        username: "", //姓名
        mobile: "", //电话
        email: "", //邮箱
        cszb: "", //组别
        zhicheng_id: "", //职称id
        department_id: "", //科室id
        province: "", //省
        city: "", //市
        district: "", //区
        hospital: "", //医院
        gzzpath: "", //工作证图片
        dwzmpath: "", //单位证明图片
        videoUrl: "", //视频地址
      },
      // 组别列表
      zubieList: [
        {
          id: 3,
          name: "血透组",
        },
        {
          id: 4,
          name: "腹透组",
        },
        {
          id: 5,
          name: "CRRT组",
        },
        {
          id: 6,
          name: "技师组",
        },
      ],
      // 职称列表
      zhichengList: [
        {
          id: 0,
          name: "请先选择参赛组别",
        },
      ],
      hs_zhicheng: [], //净护天使
      js_zhicheng: [], //竞技精英
      // 科室列表
      DepartmentList: [],
      // 请求医院页码
      pageSize: {
        page: 1,
        limit: 10,
      },
      count: 0, //医院总数
      dialogVisible: false, //提示对话框
      hospitalListIsShow: false,
      // 地图列表
      mapList: {
        province: [],
        city: [],
        area: [],
        hospital: [],
      },

      rules: {
        // 姓名
        username: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          {
            min: 2,
            max: 8,
            message: "长度应在 2 到 8 个字符",
            trigger: "blur",
          },
        ],
        // 电话
        mobile: [
          { required: true, message: "请输入您的电话", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        // 邮箱
        email: [
          { required: false, message: "请输入您的邮箱", trigger: "blur" },
          {
            pattern:
              /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|8]\d{9}$)/,
            message: "请输入正确的邮箱格式",
            trigger: "blur",
          },
        ],
        // 组别
        cszb: [
          { required: true, message: "请选择您的参赛组别", trigger: "blur" },
        ],
        // 科室
        department_id: [
          { required: true, message: "请选择您的科室", trigger: "change" },
        ],
        // 职称
        zhicheng_id: [
          { required: true, message: "请选择您的职称", trigger: "change" },
        ],
        // 地区
        city: [
          { required: true, message: "请选择您的区域", trigger: "change" },
        ],
        // 医院
        hospital: [
          { required: true, message: "请选择您的医院", trigger: "blur" },
        ],
        // 工作证明
        gzzpath: [
          { required: true, message: "请上传您的工作证明", trigger: "blur" },
        ],
        // 上传视频
        path: [
          { required: true, message: "请上传您的参赛视频", trigger: "blur" },
        ],
        // 视频简介
        intro: [
          { required: true, message: "请简单描述您的视频", trigger: "blur" },
        ],
      },
      // 输入框状态
      inputState: true,
      workInputState: false,
    };
  },
  components: {
    footerCard,
    workTemplate,
  },
  created() {
    this.isBaoming(); //判断用户是否已参赛
    this.MygetUserInfo(getToken().user_id); //判断是否填写身份信息
    this.getProvince(0); //获取地区下拉列表
    this.envChange(); //获取当前环境域名
    this.getUserInfoHandle({
      user_id: getToken().user_id,
      phase: localStorage.getItem("phase"),
    });
  },
  methods: {
    // 当前环境
    envChange() {
      let myserve = window.location.origin.split("//")[1];
      if (myserve.split(":")[0] == "localhost") {
        this.picURL = "http://testgyyc.gytcare.com";
      } else {
        this.picURL = window.location.origin;
      }
      console.log(this.picURL);
    },
    // 判断个人信息是否填写身份信息
    async MygetUserInfo(id) {
      const { data: res } = await getUserInfo({ user_id: id });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      if (res.data.user.zjhm == "") {
        this.$message.warning("请先填写身份信息");
        return this.$router.push({ name: "UserInfo" });
      }
    },
    // 判断用户是否报名
    async isBaoming() {
      // 判断比赛是否过期
      let date1 = new Date(localStorage.endtime);
      let date2 = new Date(localStorage.starttime);
      let enddate = date1.getTime(date1); //结束时间
      let starttime = date2.getTime(date2); //开始时间
      let nowdate = Date.now(); //当前时间
      if (nowdate > enddate) {
        this.$message.error("报名已结束");
        return this.$router.push({ name: "User" });
      }
      if (nowdate < starttime) {
        this.$message.error("报名未开始");
        return this.$router.push({ name: "User" });
      }
      const { data: res } = await getEntryInformation({
        phase: localStorage.getItem("phase"),
        user_id: getToken().user_id,
      });
      // res.data.state
      const state = res.data.state;
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      // 未报名
      if (state === 0) {
        this.inputState = false;
      }
      // 审核中
      if (state === 1) {
        this.inputState = false;
        return this.$message.warning("您的作品正在审核中");
      }
      // 审核失败
      if (state === 2) {
        this.inputState = false;
        return this.$message.warning(
          "您的作品未通过审核，请重新提交"
        );
      }
      // 审核通过
      if (state === 3 || state === 4 || state === 5) {
        this.$message.warning("您的作品已通过审核");
        this.workInputState = true;
      }
    },
    dialog2Show() {
      this.dialog2 = true;
    },
    // 报名参赛提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.hospitalListIsShow) {
            return this.$message("请选择您的医院");
          }
          if (this.count === 0) {
            this.ruleForm.hospital_id = 0;
          }
          if (this.ruleForm.gzzpath == "" && this.ruleForm.dwzmpath == "") {
            document.documentElement.scrollTop = 800;
            return this.$message("工作证明和单位证明请至少上传一个");
          }
          if (this.ruleForm.path == "") {
            document.documentElement.scrollTop = 1200;
            return this.$message("请先上传参赛视频");
          }
          this.ruleForm.phase = this.phase;
          delete this.ruleForm.bm_id;
          const { data: res } = await setBaoming(this.ruleForm);
          if (res.code !== "0000") {
            return this.$message.error(res.msg);
          }
          this.$message.success("报名成功，请等待系统审核");
          this.$router.push({ name: "userWork" });
          document.documentElement.scrollTop = 300;
        } else {
          if (this.ruleForm.intro == "") {
            return false;
          }
          if (this.ruleForm.path == "") {
            return false;
          }
          document.documentElement.scrollTop = 500;
          return false;
        }
      });
    },
    //上传视频事件
    httpRequest(file) {
      console.log(file);
      if (this.videoIsUPloding) {
        return this.$message.error("正在上传视频，请等待视频上传完成");
      }
      const isMp4 = file.raw.type === "video/mp4";
      const isQuicktime = file.raw.type === "video/quicktime";
      const isMov = file.raw.type === "video/mov";
      const isLt2M = file.raw.size / 1024 / 1024 < 800;
      if (!isLt2M) {
        return this.$message.error("上传视频大小不能超过 800MB!");
      }
      // 判断视频格式是否正确
      if (isMp4 || isQuicktime ||isMov) {
        this.ruleForm.path = "";
        this.file = file.raw;
        if (!this.file) {
          alert("请先选择需要上传的文件!");
          return;
        }
        if (this.uploader) {
          this.uploader.stopUpload();
          this.authProgress = 0;
          this.statusText = "";
        }
        this.uploader = this.createUploader();
        const nowTime = Date.now();
        // Title自定义上传至阿里云的文件名
        var Title =
          localStorage.getItem("phase") +
          "-" +
          this.ruleForm.user_id +
          "-" +
          this.ruleForm.mobile +
          "-" +
          nowTime;

        var paramData =
          '{"Vod":{"Title":"' + Title + '","CateId":"1000401187","TemplateGroupId":"dbd8e9485f4160e87b7b1fd1f840323e"}}';
        this.uploader.addFile(this.file, null, null, null, paramData);
        // 设置为正在上传视频的状态
        this.videoIsUPloding = true;
        return this.stsUpload();
      } else {
        return this.$message.error(
          "你的视频格式是" + file.raw.type + ",视频只能是mp4格式!"
        );
      }
    },
    // 开始上传
    stsUpload() {
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
        this.pauseDisabled = false;
      }
    },
    // 暂停上传
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload();
        this.resumeDisabled = false;
        this.pauseDisabled = true;
      }
    },
    // 恢复上传
    resumeUpload() {
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.resumeDisabled = true;
        this.pauseDisabled = false;
      }
    },
    createUploader() {
      let self = this;
      let uploader = new AliyunUpload.Vod({
        timeout: self.timeout || 60000,
        partSize: self.partSize || 1048576,
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        region: self.region,
        userId: self.userId,
        // 添加文件成功
        addFileSuccess: function (uploadInfo) {
          self.uploadDisabled = false;
          self.resumeDisabled = false;
          self.statusText = "添加文件成功, 等待上传...";
        },
        // 开始上传
        onUploadstarted: async function (uploadInfo) {
          // 如果是 STSToken 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 用户需要自己获取 accessKeyId, accessKeySecret,secretToken
          // 下面的 URL 只是测试接口, 用于获取 测试的 accessKeyId, accessKeySecret,secretToken
          const res = await getSTSToken();
          if (res.data.code == "0000") {
            let info = res.data.data;
            let accessKeyId = info.AccessKeyId;
            let accessKeySecret = info.AccessKeySecret;
            let secretToken = info.SecurityToken;
            uploader.setSTSToken(
              uploadInfo,
              accessKeyId,
              accessKeySecret,
              secretToken
            );
            self.statusText = "文件开始上传...";
          } else {
            this.$message.error(res.data.msg);
            this.videoIsUPloding = false;
          }
        },
        // 文件上传成功
        onUploadSucceed: (uploadInfo) => {
          this.$message.success("视频上传成功");
          this.videoIsUp = true;
          this.ruleForm.video_id = uploadInfo.videoId;
          this.ruleForm.path = uploadInfo.object;
          self.statusText = "文件上传成功!";
          this.videoIsUPloding = false;
        },
        // 文件上传失败
        onUploadFailed: (uploadInfo, code, message) => {
          this.$message.error("视频上传失败，请尝试给视频改个名字再上传");
          self.statusText = "文件上传失败!";
          this.videoIsUPloding = false;
        },
        // 取消文件上传
        onUploadCanceled: function (uploadInfo, code, message) {
          self.statusText = "文件已暂停上传";
          this.videoIsUPloding = false;
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          let progressPercent = Math.ceil(progress * 100);
          self.stsProgress = progressPercent;
          self.statusText = "文件上传中...";
        },
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo) {
          // 如果是上传方式二即根据 STSToken 实现时，从新获取STS临时账号用于恢复上传
          // 上传文件过大时可能在上传过程中 sts token 就会失效, 所以需要在 token 过期的回调中调用 resumeUploadWithSTSToken 方法
          // 这里是测试接口, 所以我直接获取了 STSToken
          let stsUrl = "http://www.gyycbj.com/Wechat/getSTSToken";
          axios.post(stsUrl).then(({ data }) => {
            let info = data.data;
            let accessKeyId = info.AccessKeyId;
            let accessKeySecret = info.AccessKeySecret;
            let secretToken = info.SecurityToken;
            let expiration = info.Expiration;
            uploader.resumeUploadWithSTSToken(
              accessKeyId,
              accessKeySecret,
              secretToken,
              expiration
            );
          });
          self.statusText = "文件超时...";
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
          self.statusText = "文件上传完毕";
        },
      });
      return uploader;
    },
    // 医院列表触底
    async hospitalDel() {
      if (this.mapList.hospital.length < this.count) {
        this.pageSize.page++;
        // 获取医院信息
        const { data: res } = await searchHospital({
          hospital_name: this.ruleForm.hospital,
          ...this.pageSize,
        });
        if (res.code == "0000") {
          this.mapList.hospital.push(...res.data.list);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else {
        return;
      }
    },
    // 输入医院
    hospitalChange() {
      this.hospitalListIsShow = true;
      this.gethospital({
        ...this.pageSize,
        hospital_name: this.ruleForm.hospital,
      });
    },
    hospitalChange2() {
      this.ruleForm.hospital_id = 0;
    },
    // 选择医院
    choiceHospital(item) {
      this.ruleForm.hospital = item.name;
      this.ruleForm.hospital_id = item.id;
      this.hospitalListIsShow = false;
    },
    // 关闭医院列表
    offHospital() {
      this.hospitalListIsShow = false;
    },
    // 获取医院列表
    async gethospital(data) {
      const { data: res } = await searchHospital(data);
      this.mapList.hospital = res.data.list;
      this.count = res.data.count;
      if (this.count === 0) {
        this.hospitalListIsShow = false;
        this.ruleForm.hospital_id = 0;
      }
    },
    // 获取用户信息
    async getUserInfoHandle(data) {
      const { data: res } = await getBmInfo(data);
      this.DepartmentList = res.data.department;
      this.hs_zhicheng = res.data.hs_zhicheng;
      this.js_zhicheng = res.data.js_zhicheng;
      this.ruleForm = res.data.user;
      this.zhichengList = res.data.hs_zhicheng;
      for (const key in this.ruleForm) {
        if (this.ruleForm[key] == 0) {
          this.ruleForm[key] = "";
        }
      }
    },
    // 组别切换
    mygroupChange(num) {
      this.ruleForm.cszb = num;
    },

    // 选择省/市信息
    provinceChange() {
      this.ruleForm.city = "";
      this.ruleForm.district = "";
      this.mapList.city = [];
      this.mapList.area = [];
    },
    cityChange() {
      this.ruleForm.district = "";
      this.mapList.area = [];
    },

    // 获取省份信息
    async getProvince(id) {
      const { data: res } = await getAreas(id);
      if (res.code == "0000") {
        this.mapList.province = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },

    // 图片上传成功回调
    handleAvatarSuccess(res) {
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.ruleForm.gzzpath = res.data.path;
    },
    handleAvatarSuccess2(res) {
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.ruleForm.dwzmpath = res.data.path;
    },
    beforeAvatarUpload(file) {
      const isImage =
        file.type == "image/png" ||
        file.type == "image/jpg" ||
        file.type == "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传工作证明图片只能是 JPG 和 PNG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传单位证明图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    // 关闭对话框
    handleClose() {
      this.dialogVisible = false;
    },
    // 上传视频
    videohandleAvatarSuccess(res, file) {
      this.ruleForm.videoUrl = URL.createObjectURL(file.raw);
    },
    videobeforeAvatarUpload(file) {},
  },
  // 监听器
  watch: {
    "ruleForm.province": async function (newV) {
      if (newV == "") {
        return;
      }
      // 获取市信息
      const { data: res } = await getAreas(newV);
      if (res.code == "0000") {
        this.mapList.city = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    "ruleForm.city": async function (newV) {
      if (newV == "") {
        return;
      }
      // 获取区信息
      const { data: res } = await getAreas(newV);
      if (res.code == "0000") {
        this.mapList.area = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    "ruleForm.zhicheng_id": function (newV) {
      const newArr = this.zhichengList.filter((item) => {
        return item.id == newV;
      });
      if (newArr.length < 1) return;
      this.ruleForm.zhicheng = newArr[0].name;
    },
    "ruleForm.hospital": function (newV) {
      if (newV == "") {
        this.hospitalListIsShow = false;
      }
    },
    "ruleForm.cszb": function (newV) {
      if (newV == 6) {
        this.zhichengList = this.js_zhicheng;
      } else {
        this.zhichengList = this.hs_zhicheng;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.signup {
  .textcontent {
    width: 1200px;
    margin: 0px auto;
    @media screen and (max-width: 520px) {
      width: 100%;
    }
    h2 {
      font-size: 26px;
      text-align: center;
      color: #05258e;
      margin-bottom: 10px;
      @media screen and (max-width: 520px) {
        padding-top: 20px;
        font-size: 16px;
      }
    }
    .span {
      display: block;
      margin: 0px auto;
      width: 24px;
      height: 4px;
      background: #05258e;
      border-radius: 2px;
      margin-bottom: 38px;
      @media screen and (max-width: 520px) {
        height: 2px;
        margin-bottom: 18px;
      }
    }
    .box {
      width: 1269px;
      @media screen and (max-width: 520px) {
        width: 100%;
        height: 100%;
        padding: 0;
        margin-bottom: 50px;
      }
      background: #ffffff;
      padding: 50px 0;
      margin-bottom: 150px;
      .text {
        p {
          font-size: 20px;
          color: #39373b;
          line-height: 35px;
          padding-bottom: 20px;
          @media screen and (max-width: 520px) {
            font-size: 14px;
            line-height: 20px;
          }
          a {
            color: #05258e;
            font-weight: 600;
          }
          .span1 {
            padding: 20px 0 15px 130px;
            @media screen and (max-width: 520px) {
              padding: 10px 0 10px 30px;
            }
          }
          span {
            display: block;
          }
        }
      }
    }
  }
  .myswitch {
    position: absolute;
    right: 400px;
    top: 10px;
    @media screen and (max-width: 540px) {
      right: 10px;
    }
  }
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: #f9f9f9;
  overflow: hidden;
  // position: relative;
  ::v-deep .avatar {
    height: 108px !important;
  }
  .hospitalList {
    position: absolute;
    left: 0;
    top: 45px;
    z-index: 999;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    width: 400px;
    padding: 10px 0;
    background-color: #fff;
    @media screen and (max-width: 540px) {
      width: 280px;
    }
    ul {
      height: 280px;
      overflow-y: scroll;
      li {
        box-sizing: border-box;
        cursor: pointer;
        @media screen and (max-width: 540px) {
          padding: 5px 0;
        }
        p {
          padding: 0 15px;
          @media screen and (max-width: 540px) {
            line-height: 25px;
          }
        }
      }
      li:hover {
        background-color: #eee;
      }
    }
  }
  .el-radio {
    padding: 5px 0;
  }

  .content {
    width: 1100px;
    margin: auto;
    background-color: #fff;
    margin-top: 50px;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    .title {
      font-size: 26px;
      font-weight: 600;
      color: #05258e;
      height: 85px;
      line-height: 85px;
      text-align: center;
      background: url("../../assets/image/login_bg.webp"), no-repeat;
      background-size: 100% 100%;
    }
    .form {
      padding: 55px 0 50px 0;
      box-sizing: border-box;
      box-shadow: -10px 0px 32px 32px rgba(24, 25, 34, 0.06);
      ::v-deep .el-form-item__label {
        color: #05258e;
        font-weight: 600;
        font-size: 15px;
      }
      .el-form-item {
        padding: 0 105px;
        @media screen and (max-width: 540px) {
          padding: 0 10px;
        }
      }
      .inputWidth {
        width: 400px;
        @media screen and (max-width: 540px) {
          width: 100%;
        }
      }
      .groupBtns {
        display: flex;
        button {
          height: 40px;
          width: 250px;
          @media screen and (max-width: 540px) {
            font-size: 12px;
            line-height: 14px;
            padding: 5px;
          }
          line-height: 40px;
          background-color: #fff;
          border: 1px solid #dcdfe6;
          list-style: none;
          margin-right: 20px;
          font-size: 15px;
          color: #47474e;
          cursor: pointer;
        }
        .glbtn {
          background-color: #05258e !important;
          color: #fff !important;
        }
      }
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        color: #05258e;
      }
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #05258e;
        border-color: #05258e !important;
      }
      .adress {
        .el-select {
          margin-right: 6px;
        }
      }
      .hospital {
        .el-select {
          width: 662px;
          margin-top: 16px;
        }
      }
      // 图片上传
      .uploader {
        padding-bottom: 30px;
        display: flex;
        @media screen and (max-width: 540px) {
          display: block;
          .avatar-uploader {
            margin-bottom: 40px;
          }
        }
        ::v-deep .el-upload {
          // border: 1px dashed #d9d9d9;
          border-radius: 6px;
          background-color: #eeeeee;
          cursor: pointer;
          position: relative;
          margin-right: 20px;
          .clear {
            z-index: 999;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #fff;
            background-color: #39373b3f;
          }
        }
        .tit {
          position: absolute;
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
          i {
            z-index: 99;
          }
        }
        .tit2 {
          position: absolute;
          bottom: -60px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 999;
          color: #409eff;
          width: 100%;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 108px;
          line-height: 108px;
          text-align: center;
        }
        .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
      }
      .height {
        height: 10px;
        width: 100%;
        background-color: #f5f5f5;
        margin-bottom: 20px;
      }
      // 视频上传
      .videoUP {
        ::v-deep .el-form-item__label {
          color: #05258e !important;
        }
        ::v-deep .el-upload-dragger {
          width: 260px;
        }
        .tishi {
          padding: 0 0 32px 0;
          .btnText {
            width: 100px;
            height: 35px;
            background: #f5f5f5;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
            padding: 5px 14px;
            cursor: pointer;
          }
        }
        .upvideo {
          .avatar-uploader {
            @media screen and (max-width: 540px) {
              padding-bottom: 200px;
            }
          }
          .myvideo {
            position: absolute;
            width: 270px;
            height: 200px;
            left: 280px;
            top: 48px;
            @media screen and (max-width: 540px) {
              position: absolute;
              width: 270px;
              height: 200px;
              left: 0;
              top: 300px;
            }
          }
          ::v-deep .el-upload {
            // border: 1px dashed #d9d9d9;
            border-radius: 6px;
            background-color: #eeeeee;
            cursor: pointer;
            position: relative;
            margin-right: 20px;
          }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 108px;
            line-height: 108px;
            text-align: center;
          }
          .avatar {
            width: 178px;
            height: 178px;
            display: block;
          }
        }
      }
      // 提交表单
      .submit {
        display: block;
        background-color: #293f8e;
        margin: auto;
        transform: translateX(-100px);
        width: 150px;
        line-height: 1.2;
      }
    }
  }
  .mydialog {
    ::v-deep .el-dialog__header {
      text-align: center;
      font-weight: 600;
      padding-top: 30px;
      span {
        font-size: 28px;
      }
    }
    .text2 {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
      }
      a {
        text-align: center;
      }
    }
  }
  .footer {
    display: block;
    margin-top: 180px;
    @media screen and (max-width: 540px) {
      display: none;
    }
  }
}
</style>
