import request from "../../assets/js/Request.js";
// import qs from 'qs'


// 获取省市区
export const getAreas = (params) => {
    return request.get("getAreas?pid="+params);
  };
  // 获取医院
export const searchHospital = (params) => {
    return request.post("searchHospital",params);
  };